import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/packages/gatsby-theme-fuegodocs/src/components/Layout/Layout.js";
import { Code } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
import { Component } from "react";
import { InlineCode } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ArticlePageWrapper = makeShortcode("ArticlePageWrapper");
const BackButton = makeShortcode("BackButton");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const DoDontChecklist = makeShortcode("DoDontChecklist");
const DoDontChecklistHeading = makeShortcode("DoDontChecklistHeading");
const DoDontChecklistItem = makeShortcode("DoDontChecklistItem");
const DoDontComponent = makeShortcode("DoDontComponent");
const Do = makeShortcode("Do");
const AdaptiveImage = makeShortcode("AdaptiveImage");
const CaptionTitle = makeShortcode("CaptionTitle");
const ImageCaption = makeShortcode("ImageCaption");
const Dont = makeShortcode("Dont");
const VercelAnalytics = makeShortcode("VercelAnalytics");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ArticlePageWrapper mdxType="ArticlePageWrapper">
      <BackButton link="/content" mdxType="BackButton" />
      <Heading as="h1" mdxType="Heading">Word choices</Heading>
      <Text mdxType="Text">
  The right words help people navigate easily and feel confident in our products. By accurately conveying the intended meaning, we strengthen our brand and ensure clarity.
      </Text>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Remove or delete?</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use <i>remove</i> to take something out of view or use without permanently eliminating it.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use <i>delete</i> to permanently erase something, making it difficult or impossible to undo.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    When in doubt, choose <i>delete</i> if the action significantly affects other data or flows.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
    <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/bcb40f72-8c7d-4b57-bab7-29cee734d0ff" darkSrc="https://github.com/user-attachments/assets/c95786b2-b7ac-41bd-b37c-626aa5072f07" alt="An example of situation when using the word remove is appropriate." mdxType="AdaptiveImage" />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      <i>Remove</i> feels softer and suggests the action is reversible or limited to a specific area. 
    </ImageCaption>
  </Do>
  <Do variant={`border`} mdxType="Do">
  <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/798aca93-a81d-4c58-9c4d-8e73e635b354" darkSrc="https://github.com/user-attachments/assets/cf7158ca-6410-47b5-b118-e51d9a64a923" alt="An example of situation when using the word delete is appropriate." mdxType="AdaptiveImage" />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      <i>Delete</i> is more definitive, prompting careful thought and deliberation.
    </ImageCaption>
  </Do>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">And/or</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid using <i>and/or</i> structure as it's redundant, confusing, and disrupts scanning.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Most of the time, <i>or</i> works just fine and doesn't exclude choosing both options.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    If stronger emphasis is needed, rephrase your message to ensure clarity.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`blockquote`} mdxType="Do">
    This phrasing remains clear while avoiding adding to cognitive load:<br></br>
    “Select users or teams with limited access to the project.”
  </Do>
  <Dont variant={`blockquote`} mdxType="Dont">
    Using <i>and/or</i> leads to over-explaining and disrupts communication:<br></br>
    “Select users and/or teams with limited access to the project.”
  </Dont>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Starred vs. favorites</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    The nuance between those two is minimal and depends on the app-specific context.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    <i>Favorites</i> tends to carry a more emotional tone, often tied to personal preferences.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use <i>starred</i> to highlight work-related items, such as tasks or projects that need attention.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Choose <i>favorites</i> to save go-to templates, setups, filters, etc.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <hr />
      <Text mdxType="Text">Share your feedback on the #design-systems channel in Slack.</Text>
    </ArticlePageWrapper>
    <VercelAnalytics mdxType="VercelAnalytics" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      